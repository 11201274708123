@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Moderat";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/Moderat-Light.ttf");
  font-weight: light;
	font-style: normal;

}
@font-face {
  font-family: "Moderat";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/Moderat-Bold.ttf");
  font-weight: bold;
	font-style: normal;


}
@font-face {
  font-family: "Moderat";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/Moderat-Medium.ttf");
  font-weight: 500;
	font-style: normal;
}
@font-face {
  font-family: "Moderat";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/Moderat-Regular.ttf");
  font-weight: 400;
	font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/sato/OTF/Satoshi-Light.otf");
  font-weight: light;
	font-style: normal;

}
@font-face {
  font-family: "Satoshi";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/sato/OTF/Satoshi-Bold.otf");
  font-weight: bold;
	font-style: normal;


}
@font-face {
  font-family: "Satoshi";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/sato/OTF/Satoshi-Medium.otf");
  font-weight: 500;
	font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  /* src: url("../public/font/Moderat-Bolder.ttf"); */
  src: url("../public/font/sato/OTF/Satoshi-Regular.otf");
  font-weight: 400;
	font-style: normal;
}

body{
  font-family: satoshi;
  font-weight: 400;
}
html,body{
    overflow-x: hidden;
}
.tx-moderat{
  font-family: moderat;
}
.btn{
  @apply rounded-lg py-2 px-5 font-medium shadow-sm
}
.link{
  @apply text-slate-500 px-2 mx-4
}

.link-dropdown{
  @apply text-slate-500 px-5

}

.active{
  /* @apply text-primary font-semibold border-b-2 border-b-primary pb-3 */
}
.btn.active{
  @apply text-white
}
.navbar{
  @apply py-2 bg-[#F9FAFB] fixed w-full  transition-all duration-200 ;
  z-index: 9999;
}
.navbar-change{
      transition: all .4s ease-in-out!important;
      background-color: white!important;
      border-bottom: 1px solid hsla(0,0%,100%,.1);
      @apply shadow-lg py-1
}
.key-shadow{
  box-shadow: 0px 6px 30px 0px #00000014;
}
.round-bottom{
  border-radius: 0 0 95% 30% / 50px;
}
.round-top{
  border-radius: 55% 95% 0 0 / 50px;

}
.round-bottomm{
  border-radius: 50%;
}
.neg-margin{
  margin-top: -7rem;
}

.acc-item{
  margin-bottom: 10px;
  border-bottom: 1px solid #EAECF0

}

.image-shrink{
  flex-shrink: 0;
}

.content{
  overflow:hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
  max-height: 0;
}
.content.show{
  height: auto;
  max-height: 999px;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}
.blog-bg{
  background-image: url(./img/blog.jpg);
}
.leading-snug{
  line-height: 1.2!important;
}
.react-tel-input .form-control{
  width:100%!important;
  padding: 14px 14px 14px 60px!important;
  border-color: #e5e7eb!important;
}